import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type {
  ApproverTicketBody,
  TicketBody,
  TicketExportBody,
  TicketSearchQuery,
  TicketStepBody,
  TicketViewerBody,
  TicketViewerBulkBody,
  VerifyRouteBody,
} from '~/types/body/ticketBody'
import type { Progress } from '~/types/progress'
import type {
  DetailedTicket,
  TaskCount,
  TaskTicket,
  Ticket,
  TicketCount,
  TicketFieldPermissions,
  TicketForcedPublicType,
  TicketStatus,
  TicketViewer,
  TicketViewerBulkCount,
  TicketWatching,
} from '~/types/ticket'
import type { TicketLog } from '~/types/ticketLog'
import type { TicketRouteResult } from '~/types/ticketRouteResult'
import type { TicketStep } from '~/types/ticketStep'
import type { User } from '~/types/user'
import type { WorkflowReportFormat } from '~/types/workflow'

export type TransferMyTicketsSearchParams = {
  fromUserId: string
  q: string | null
  workflowId: string | null
  ticketNumber: string | null
  authorTeamFullName: string | null
  createdAtStart: string | null
  createdAtEnd: string | null
  openedAtStart: string | null
  openedAtEnd: string | null
  labelIds: string[]
  status: TicketStatus[]
  subStatusIds: string[]
  stepTitle: string | null
}

export type TransferTaskTicketsSearchParams = {
  fromUserId: string
  q: string | null
  workflowId: string | null
  ticketNumber: string | null
  authorTeamFullName: string | null
  authorId: string | null
  createdAtStart: string | null
  createdAtEnd: string | null
  openedAtStart: string | null
  openedAtEnd: string | null
  labelIds: string[]
  status: string[]
  subStatusIds: string[]
  stepTitle: string | null
}

/**
 * チケットAPI
 */
export class KickflowTicketApi extends KickflowApi {
  async getMyTickets(
    paging: PagingQuery,
    query?: Partial<TicketSearchQuery>
  ): Promise<PagingList<TaskTicket>> {
    let queryParams = paging.toParams()
    queryParams = Object.assign(queryParams, query)
    return await this.getPagingList('/tickets/createdByMe', queryParams)
  }

  async getTasks(
    paging: PagingQuery,
    query?: Partial<TicketSearchQuery>
  ): Promise<PagingList<TaskTicket>> {
    let queryParams = paging.toParams()
    queryParams = Object.assign(queryParams, query)
    return await this.getPagingList('/tickets/assigned', queryParams)
  }

  async getTaskCount(): Promise<TaskCount> {
    return await this.get('/tickets/taskCount')
  }

  async getCount(): Promise<TicketCount> {
    return await this.get('/tickets/count')
  }

  async searchTicket(
    paging: PagingQuery,
    query?: Partial<TicketSearchQuery>
  ): Promise<PagingList<Ticket>> {
    let queryParams = paging.toParams()
    queryParams = Object.assign(queryParams, query)
    return await this.getPagingList('/tickets/search', queryParams)
  }

  async getTicket(id: string): Promise<DetailedTicket> {
    return await this.get(`/tickets/${id}`)
  }

  async verifyTicketRoute(params: VerifyRouteBody): Promise<TicketRouteResult> {
    return await this.post('/tickets/route', params)
  }

  async createTicket(params: TicketBody): Promise<DetailedTicket> {
    return await this.post('/tickets', params)
  }

  async updateTicket(
    ticket: Ticket,
    params: TicketBody
  ): Promise<DetailedTicket> {
    return await this.put(`/tickets/${ticket.id}`, params)
  }

  /**
   * 承認者による更新
   * @param ticket 対象チケット
   * @param params パラメータ。フォーム入力は承認者フォーム分のみ入れてください。
   */
  async updateTicketByApprover(
    ticket: Ticket,
    params: ApproverTicketBody
  ): Promise<DetailedTicket> {
    return await this.put(`/tickets/${ticket.id}`, params)
  }

  async approveTicket(ticket: Ticket): Promise<DetailedTicket> {
    return await this.post(`/tickets/${ticket.id}/approve`)
  }

  async approveTickets(ticketIds: string[]) {
    const params = {
      ticketId: ticketIds,
    }
    return await this.post('/tickets/approve', params)
  }

  async rejectTicket(
    ticket: Ticket,
    params: { to: number }
  ): Promise<DetailedTicket> {
    return await this.post(`/tickets/${ticket.id}/reject`, params)
  }

  async denyTicket(ticket: Ticket): Promise<DetailedTicket> {
    return await this.post(`/tickets/${ticket.id}/deny`)
  }

  async suspendTicket(
    ticket: Ticket,
    params: { pending: boolean }
  ): Promise<DetailedTicket> {
    return await this.post(`/tickets/${ticket.id}/suspend`, params)
  }

  async withdrawTicket(ticket: Ticket): Promise<DetailedTicket> {
    return await this.post(`/tickets/${ticket.id}/withdraw`)
  }

  async archiveTicket(ticket: Ticket): Promise<DetailedTicket> {
    return await this.post(`/tickets/${ticket.id}/archive`)
  }

  async permanentlyDeleteTicket(ticket: Ticket): Promise<DetailedTicket> {
    return await this.delete(`/tickets/${ticket.id}`)
  }

  async raiseTicket(ticket: Ticket): Promise<DetailedTicket> {
    return await this.post(`/tickets/${ticket.id}/raise`)
  }

  async copyTicket(ticket: Ticket): Promise<DetailedTicket> {
    return await this.post(`/tickets/${ticket.id}/copy`)
  }

  async exportTickets(params: TicketExportBody): Promise<Progress> {
    return await this.get('/tickets/csv', params)
  }

  // transfer
  async transferMyTickets(params: {
    fromUserId: string
    toUserId: string
    toTeamId: string
    all: boolean
    ticketIds: string[]
  }) {
    return await this.post('/tickets/transfer/myTicket', params)
  }

  async getTransferMyTicketCount(params: {
    fromUserId: string
  }): Promise<{ count: number }> {
    return await this.get('/tickets/transfer/myTicketCount', params)
  }

  async transferMyTicketsSearch(
    paging: PagingQuery,
    query: TransferMyTicketsSearchParams
  ): Promise<PagingList<TaskTicket>> {
    const params = { ...paging.toParams(), ...query }
    return await this.getPagingList('/tickets/transfer/myTicketSearch', params)
  }

  async transferTaskTickets(params: {
    fromUserId: string
    toUserId: string
    all: boolean
    ticketIds: string[]
  }) {
    return await this.post('/tickets/transfer/task', params)
  }

  async getTransferTaskCount(params: {
    fromUserId: string
  }): Promise<{ count: number }> {
    return await this.get('/tickets/transfer/taskCount', params)
  }

  async transferTaskTicketsSearch(
    paging: PagingQuery,
    query: TransferTaskTicketsSearchParams
  ): Promise<PagingList<TaskTicket>> {
    const params = { ...paging.toParams(), ...query }
    return await this.getPagingList('/tickets/transfer/taskSearch', params)
  }

  // clean up

  async cleanTickets(params: {
    workflowId: string
    status: TicketStatus[] | null
  }) {
    return await this.post(`/workflows/${params.workflowId}/tickets/clean`, {
      status: params.status,
    })
  }

  // logs

  async getTicketLogs(
    paging: PagingQuery,
    ticket: Ticket
  ): Promise<PagingList<TicketLog>> {
    const params = paging.toParams()
    return await this.getPagingList(`/tickets/${ticket.id}/logs`, params)
  }

  // viewers

  async getViewers(
    paging: PagingQuery,
    ticket: Ticket
  ): Promise<PagingList<TicketViewer>> {
    const params = paging.toParams()
    return await this.getPagingList(`/tickets/${ticket.id}/viewers`, params)
  }

  async getViewersByTicketId(
    paging: PagingQuery,
    id: string
  ): Promise<PagingList<TicketViewer>> {
    const params = paging.toParams()
    return await this.getPagingList(`/tickets/${id}/viewers`, params)
  }

  async createViewer(ticket: Ticket, params: TicketViewerBody) {
    return await this.post(`/tickets/${ticket.id}/viewers`, params)
  }

  async deleteViewer(ticket: Ticket, viewer: TicketViewer) {
    return await this.delete(`/tickets/${ticket.id}/viewers/${viewer.id}`)
  }

  async bulkCountViewers(
    params: TicketViewerBulkBody
  ): Promise<TicketViewerBulkCount> {
    return await this.post('/tickets/viewers/bulkCount', params)
  }

  async bulkViewers(params: TicketViewerBulkBody) {
    return await this.post('/tickets/viewers/bulk', params)
  }

  async bulkDeleteViewers(params: TicketViewerBulkBody) {
    return await this.post('/tickets/viewers/bulkDestroy', params)
  }

  // public type

  async updateForcedPublicType(
    ticket: Ticket,
    type: TicketForcedPublicType
  ): Promise<DetailedTicket> {
    const params = {
      forcedPublicType: type,
    }
    return await this.post(`/tickets/${ticket.id}/forcedPublicType`, params)
  }

  // watchers

  async getWatchersByTicketId(
    paging: PagingQuery,
    id: string
  ): Promise<PagingList<User>> {
    const queryParams = paging.toParams()
    return await this.getPagingList(`/tickets/${id}/watchers`, queryParams)
  }

  async checkWatching(id: string): Promise<TicketWatching> {
    return await this.get(`/tickets/${id}/watch`)
  }

  async watchTicket(ticket: Ticket) {
    return await this.post(`/tickets/${ticket.id}/watch`)
  }

  async unwatchTicket(ticket: Ticket) {
    return await this.delete(`/tickets/${ticket.id}/unwatch`)
  }

  // links

  async getLinkedTickets(
    ticket: Ticket,
    paging: PagingQuery
  ): Promise<PagingList<Ticket>> {
    return await this.getLinkedTicketsByTicketId(ticket.id, paging)
  }

  async getLinkedTicketsByTicketId(
    id: string,
    paging: PagingQuery
  ): Promise<PagingList<Ticket>> {
    const queryParams = paging.toParams()
    return await this.getPagingList(`/tickets/${id}/links`, queryParams)
  }

  async createLinkedTicket(ticket: Ticket, linkedTicketId: string[]) {
    const data = {
      linkedTicketId,
    }
    return await this.post(`/tickets/${ticket.id}/links`, data)
  }

  async deleteLinkedTicket(ticket: Ticket, linkedTicketId: string) {
    return await this.delete(`/tickets/${ticket.id}/links/${linkedTicketId}`)
  }

  // steps

  async addStepByApprover(
    ticket: Ticket,
    params: TicketStepBody
  ): Promise<DetailedTicket> {
    return await this.post(`/tickets/${ticket.id}/steps`, params)
  }

  async deleteStepByApprover(
    ticket: Ticket,
    ticketStepId: string
  ): Promise<DetailedTicket> {
    return await this.delete(`/tickets/${ticket.id}/steps/${ticketStepId}`)
  }

  // excel

  async createReport(
    ticket: Ticket,
    report_format: WorkflowReportFormat
  ): Promise<Progress> {
    const params = { report_format }
    return await this.post(`/tickets/${ticket.id}/reports`, params)
  }

  // assignees

  /**
   * 次のステップの承認者を指名する
   * @param ticket
   * @param ticketStepApprovers キーがステップID, 値がユーザー配列のオブジェクト
   */
  async postNextAssignees(
    ticket: Ticket,
    ticketStepApprovers: Record<string, User[]>
  ): Promise<DetailedTicket> {
    const steps = []
    for (const [ticketStepId, approverUsers] of Object.entries(
      ticketStepApprovers
    )) {
      steps.push({
        id: ticketStepId,
        approver_user_ids: approverUsers.map((user) => user.id),
      })
    }
    return await this.post(`/tickets/${ticket.id}/assignees`, { steps })
  }

  /**
   * 管理者による承認者追加
   * @param ticket
   * @param step
   * @param userId
   */
  async addAssigneeByAdmin(ticket: Ticket, step: TicketStep, userId: string) {
    const params = { userId }
    return await this.post(
      `/tickets/${ticket.id}/steps/${step.id}/assignees`,
      params
    )
  }

  // edit permissions
  async getFieldPermissions(ticketId: string): Promise<TicketFieldPermissions> {
    return await this.get(`/tickets/${ticketId}/fieldPermissions`, {})
  }
}
