import {
  fetchAndActivate,
  getBoolean,
  getRemoteConfig,
  type RemoteConfig,
} from '@firebase/remote-config'
import {
  type FirebaseApp,
  type FirebaseOptions,
  initializeApp,
} from 'firebase/app'
import {
  deleteToken,
  getMessaging,
  getToken,
  isSupported,
  type Messaging,
  onMessage,
} from 'firebase/messaging'

import type { NuxtApp } from '#app'

// Remote Configのキーとデフォルト値
const defaultRemoteConfig: Record<string, boolean> = {
  featureFlagHoge: false,
  featureFlagApprovalCancellable: false,
}

type RemoteConfigKey = keyof typeof defaultRemoteConfig

export class Firebase {
  private readonly firebaseApp: FirebaseApp
  private readonly publicVapidKey: string
  private readonly remoteConfig: RemoteConfig

  // FCMがサポートされていない環境ではundefined
  private messaging?: Messaging

  constructor(context: NuxtApp) {
    const options: FirebaseOptions = {
      apiKey: context.$config.public.firebaseApiKey,
      authDomain: context.$config.public.firebaseAuthDomain,
      projectId: context.$config.public.firebaseProjectId,
      storageBucket: context.$config.public.firebaseStorageBucket,
      messagingSenderId: context.$config.public.firebaseMessagingSenderId,
      appId: context.$config.public.firebaseAppId,
      measurementId: context.$config.public.firebaseMeasurementId,
    }
    this.firebaseApp = initializeApp(options)
    this.publicVapidKey = context.$config.public.firebasePublicVapidKey

    // remote config
    this.remoteConfig = getRemoteConfig(this.firebaseApp)
    if (context.$config.public.firebaseRemoteConfigFetchIntervalMillis) {
      this.remoteConfig.settings.minimumFetchIntervalMillis = parseInt(
        context.$config.public.firebaseRemoteConfigFetchIntervalMillis
      )
    }
    this.remoteConfig.defaultConfig = defaultRemoteConfig
  }

  /**
   * FCMを初期化する
   * サポートされているかどうかは非同期でしか取得できないため、コンストラクタではなくここで初期化している
   */
  async initializeMessaging() {
    // フォアグラウンドでの受信処理
    // 中では、ペイロードから通知情報（タイトルなど）を取り出してService workerに投げつけているだけ
    const supported = await isSupported()
    if (supported) {
      this.messaging = getMessaging(this.firebaseApp)
      onMessage(this.messaging, async (payload) => {
        if (payload.notification) {
          const { title, ...options } = payload.notification
          // 送信ペイロードのwebpush以下が欠落しているので、アイコンだけここで再度セット
          options.icon = '/firebase-icon.png'
          const swRegistration = await navigator.serviceWorker.ready
          await swRegistration.showNotification(title || '', options)
        }
      })
    }
  }

  /**
   * FCMトークンを取得する
   * Nuxt PWAのService workerを使用するため、このメソッド経由でトークンを取得してください
   * 古いiOSデバイスではサポートされていないため、その場合は空文字を返します
   */
  async getToken(): Promise<string> {
    const supported = await isSupported()
    if (!supported) {
      return ''
    }

    // Service workerのURL
    // 本番では /sw.js だが、開発環境では /dev-sw.js?dev-sw になる
    const url =
      process.env.NODE_ENV === 'production' ? '/sw.js' : '/dev-sw.js?dev-sw'
    const swRegistration = await navigator.serviceWorker.register(url)
    return await getToken(this.messaging!, {
      vapidKey: this.publicVapidKey,
      serviceWorkerRegistration: swRegistration,
    })
  }

  /**
   * FCMトークンを削除する
   * 古いiOSデバイスではサポートされていないため、その場合は何もしない
   */
  async deleteToken() {
    const supported = await isSupported()
    if (supported) {
      await deleteToken(this.messaging!)
    }
  }

  /**
   * FCMがサポートされているかどうかを返す
   */
  async isMessagingSupported(): Promise<boolean> {
    return await isSupported()
  }

  /**
   * Remote Configを更新する
   */
  async refreshRemoteConfig() {
    await fetchAndActivate(this.remoteConfig)
  }

  /**
   * Remote Configからブール型の値を取得する
   * @param key
   */
  getRemoteConfigBoolean(key: RemoteConfigKey): boolean {
    return getBoolean(this.remoteConfig, key)
  }

  // 以下、フィーチャーフラグの個別メソッド

  // noinspection JSUnusedGlobalSymbols
  featureFlagHoge(): boolean {
    return this.getRemoteConfigBoolean('featureFlagHoge')
  }

  featureFlagApprovalCancellable(): boolean {
    return this.getRemoteConfigBoolean('featureFlagApprovalCancellable')
  }
}

export default defineNuxtPlugin(async (nuxtApp) => {
  const firebase = new Firebase(nuxtApp as NuxtApp)
  await firebase.initializeMessaging()
  return {
    provide: {
      firebase,
    },
  }
})
