import { KickflowApi } from '~/api/kickflowApi'
import type { GptProgress } from '~/types/ai'
import type { FormAssistBody, InputAssistBody } from '~/types/body/aiBody'

/**
 * AI API
 */
export class KickflowAiApi extends KickflowApi {
  /*
   * 入力補助
   */
  async createInputAssist(params: InputAssistBody): Promise<GptProgress> {
    return await this.post('/ai/inputAssists', params)
  }

  async getInputAssist(id: string): Promise<GptProgress> {
    return await this.get(`/ai/inputAssists/${id}`)
  }

  /*
   * フォーム作成補助
   */

  async createFormAssist(params: FormAssistBody): Promise<GptProgress> {
    return await this.post('/ai/formAssists', params)
  }

  async getFormAssist(id: string): Promise<GptProgress> {
    return await this.get(`/ai/formAssists/${id}`)
  }
}
