import validate from "/tmp/build_89936778/frontend_nuxt3/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45revision_45global from "/tmp/build_89936778/frontend_nuxt3/middleware/01.revision.global.ts";
import _02_45subdomain_45global from "/tmp/build_89936778/frontend_nuxt3/middleware/02.subdomain.global.ts";
import manifest_45route_45rule from "/tmp/build_89936778/frontend_nuxt3/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45revision_45global,
  _02_45subdomain_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "admin-audit": () => import("/tmp/build_89936778/frontend_nuxt3/middleware/admin-audit.ts"),
  "admin-automation": () => import("/tmp/build_89936778/frontend_nuxt3/middleware/admin-automation.ts"),
  "admin-billing": () => import("/tmp/build_89936778/frontend_nuxt3/middleware/admin-billing.ts"),
  "admin-connection": () => import("/tmp/build_89936778/frontend_nuxt3/middleware/admin-connection.ts"),
  "admin-label": () => import("/tmp/build_89936778/frontend_nuxt3/middleware/admin-label.ts"),
  "admin-master": () => import("/tmp/build_89936778/frontend_nuxt3/middleware/admin-master.ts"),
  "admin-role": () => import("/tmp/build_89936778/frontend_nuxt3/middleware/admin-role.ts"),
  "admin-security": () => import("/tmp/build_89936778/frontend_nuxt3/middleware/admin-security.ts"),
  "admin-stats": () => import("/tmp/build_89936778/frontend_nuxt3/middleware/admin-stats.ts"),
  "admin-team": () => import("/tmp/build_89936778/frontend_nuxt3/middleware/admin-team.ts"),
  "admin-tenant": () => import("/tmp/build_89936778/frontend_nuxt3/middleware/admin-tenant.ts"),
  "admin-ticket-write": () => import("/tmp/build_89936778/frontend_nuxt3/middleware/admin-ticket-write.ts"),
  "admin-user": () => import("/tmp/build_89936778/frontend_nuxt3/middleware/admin-user.ts"),
  "admin-workflow": () => import("/tmp/build_89936778/frontend_nuxt3/middleware/admin-workflow.ts"),
  admin: () => import("/tmp/build_89936778/frontend_nuxt3/middleware/admin.ts"),
  authenticated: () => import("/tmp/build_89936778/frontend_nuxt3/middleware/authenticated.ts"),
  "subdomain-app": () => import("/tmp/build_89936778/frontend_nuxt3/middleware/subdomain-app.ts"),
  "subdomain-box": () => import("/tmp/build_89936778/frontend_nuxt3/middleware/subdomain-box.ts"),
  "subdomain-slack": () => import("/tmp/build_89936778/frontend_nuxt3/middleware/subdomain-slack.ts"),
  subscription: () => import("/tmp/build_89936778/frontend_nuxt3/middleware/subscription.ts")
}