import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type {
  CategoryCreateBody,
  CategoryUpdateBody,
} from '~/types/body/categoryBody'
import type {
  WorkflowCreateBody,
  WorkflowUpdateBody,
  WorkflowVersionBody,
} from '~/types/body/workflowBody'
import type { Category } from '~/types/category'
import type { Encoding } from '~/types/encoding'
import type { Progress } from '~/types/progress'
import type { TicketStatus } from '~/types/ticket'
import type {
  DetailedWorkflow,
  Workflow,
  WorkflowReportFormat,
  WorkflowStatus,
  WorkflowVersion,
} from '~/types/workflow'
import type { WorkflowTemplate } from '~/types/workflowTemplate'

/**
 * ワークフローAPI
 */
export class KickflowWorkflowApi extends KickflowApi {
  /**
   * すべてのWFを取得
   * @param paging
   * @param status
   */
  async getWorkflows(
    paging: PagingQuery,
    status: string[],
    options?: { hiddenOnWorkflowFilterForTicket: boolean }
  ): Promise<PagingList<Workflow>> {
    const queryParams = paging.toParams()
    queryParams.status = status
    if (options?.hiddenOnWorkflowFilterForTicket) {
      queryParams.hiddenOnWorkflowFilterForTicket = true
    }
    return await this.getPagingList('/workflows', queryParams)
  }

  /**
   * すべてのWFを検索
   * @param paging
   * @param q
   * @param status
   */
  async searchWorkflows(
    paging: PagingQuery,
    q: string,
    status: string[],
    options?: { hiddenOnWorkflowFilterForTicket: boolean }
  ): Promise<PagingList<Workflow>> {
    const queryParams = paging.toParams()
    queryParams.q = q
    queryParams.status = status
    if (options?.hiddenOnWorkflowFilterForTicket) {
      queryParams.hiddenOnWorkflowFilterForTicket = true
    }
    return await this.getPagingList('/workflows/search', queryParams)
  }

  /**
   * 申請可能なWFを取得
   * @param paging
   * @param categoryIds
   * @param folderId
   */
  async getSubmittableWorkflows(
    paging: PagingQuery,
    categoryIds: string[] | null,
    folderId: string[] | null
  ): Promise<PagingList<Workflow>> {
    const queryParams = paging.toParams()
    if (categoryIds) {
      queryParams.categoryIds = categoryIds
    }
    if (folderId) {
      queryParams.folderId = folderId
    }
    return await this.getPagingList('/workflows/submittable', queryParams)
  }

  /**
   * 申請可能なWFを検索
   * @param paging
   * @param q
   * @param categoryIds
   * @param folderId
   */
  async searchSubmittableWorkflows(
    paging: PagingQuery,
    q: string,
    categoryIds: string[] | null,
    folderId: string[] | null
  ): Promise<PagingList<Workflow>> {
    const queryParams = paging.toParams()
    queryParams.q = q
    if (categoryIds) {
      queryParams.categoryIds = categoryIds
    }
    if (folderId) {
      queryParams.folderId = folderId
    }
    return await this.getPagingList(
      '/workflows/submittable/search',
      queryParams
    )
  }

  /**
   * 管理可能なWFを取得・検索
   * @param paging
   * @param q
   * @param status
   * @param folderId
   */
  async getManageableWorkflows(
    paging: PagingQuery,
    q: string | null,
    status: string[],
    folderId: string[],
    categoriyIds: string[]
  ): Promise<PagingList<Workflow>> {
    const queryParams = paging.toParams()
    if (q) {
      queryParams.q = q
    }
    queryParams.status = status
    if (folderId) {
      queryParams.folderId = folderId
    }
    if (categoriyIds) {
      queryParams.categoryIds = categoriyIds
    }
    return await this.getPagingList('/workflows/manageable', queryParams)
  }

  async getRecentWorkflows(): Promise<Workflow[]> {
    return await this.get('/workflows/recent')
  }

  async getWorkflow(id: string): Promise<DetailedWorkflow> {
    return await this.get(`/workflows/${id}`)
  }

  async createWorkflow(params: WorkflowCreateBody): Promise<DetailedWorkflow> {
    return await this.post('/workflows', params)
  }

  async updateWorkflow(
    workflow: Workflow,
    params: WorkflowUpdateBody
  ): Promise<DetailedWorkflow> {
    return await this.patch(`/workflows/${workflow.id}`, params)
  }

  async bulkUpdateWorkflow(
    workflowIds: string[],
    status: WorkflowStatus | null,
    folderId: string | null
  ) {
    const params: any = {
      workflowId: workflowIds,
    }
    if (status) {
      params.status = status
    }
    if (folderId) {
      params.folderId = folderId
    }
    return await this.patch('/workflows/bulkUpdate', params)
  }

  async deleteWorkflow(workflow: Workflow) {
    return await this.delete(`/workflows/${workflow.id}`)
  }

  async bulkDestroyWorkflow(workflowIds: string[]) {
    const params: any = {
      workflowId: workflowIds,
    }
    return await this.delete('/workflows/bulkDestroy', params)
  }

  async copyWorkflow(workflow: Workflow): Promise<DetailedWorkflow> {
    return await this.post(`/workflows/${workflow.id}/copy`)
  }

  /*
   * Versions
   */

  async getWorkflowVersions(
    workflow: Workflow,
    paging: PagingQuery
  ): Promise<PagingList<WorkflowVersion>> {
    const queryParams = paging.toParams()
    return await this.getPagingList(
      `/workflows/${workflow.id}/versions`,
      queryParams
    )
  }

  async getWorkflowVersion(
    id: string,
    versionNumber: string
  ): Promise<WorkflowVersion> {
    return await this.get(`/workflows/${id}/versions/${versionNumber}`)
  }

  async createWorkflowVersion(
    workflow: Workflow,
    params: WorkflowVersionBody
  ): Promise<WorkflowVersion> {
    return await this.post(`/workflows/${workflow.id}/versions`, params)
  }

  async activateWorkflowVersion(
    workflowVersion: WorkflowVersion
  ): Promise<WorkflowVersion> {
    const { id, versionNumber } = workflowVersion
    return await this.post(
      `/workflows/${id}/versions/${versionNumber}/activate`
    )
  }

  async createWorkflowVersionPlan(
    workflowVersion: WorkflowVersion,
    dueOn: string
  ): Promise<WorkflowVersion> {
    const { id, versionNumber } = workflowVersion
    const params = { dueOn }
    return await this.post(
      `/workflows/${id}/versions/${versionNumber}/plan`,
      params
    )
  }

  async destroyWorkflowVersionPlan(
    workflowVersion: WorkflowVersion
  ): Promise<WorkflowVersion> {
    const { id, versionNumber } = workflowVersion
    return await this.delete(`/workflows/${id}/versions/${versionNumber}/plan`)
  }

  /*
   * Excel template
   */

  async createExcelTemplate(
    workflow: Workflow,
    file: string
  ): Promise<Progress> {
    return await this.post(`/workflows/${workflow.id}/excelTemplate`, { file })
  }

  async updateExcelTemplate(
    workflow: Workflow,
    params: {
      restrictedExport: boolean
      status: TicketStatus[]
      subStatusIds: string[]
    }
  ): Promise<Progress> {
    return await this.patch(`/workflows/${workflow.id}/excelTemplate`, params)
  }

  async deleteExcelTemplate(workflow: Workflow) {
    return await this.delete(`/workflows/${workflow.id}/excelTemplate`)
  }

  async updateReportFormats(
    workflow: Workflow,
    reportFormats: WorkflowReportFormat[]
  ) {
    const params = { reportFormats }
    return await this.put(`/workflows/${workflow.id}/report`, params)
  }

  /*
   * Templates
   */

  async getWorkflowTemplates(): Promise<WorkflowTemplate[]> {
    return await this.get('/workflows/templates')
  }

  /*
   * Import & Export
   */
  async import(signedId: string): Promise<Progress> {
    return await this.post('/workflows/import', { file: signedId })
  }

  async export(): Promise<Progress> {
    return await this.post('/workflows/export')
  }

  /*
   * categories
   */

  async getCategories(paging: PagingQuery): Promise<PagingList<Category>> {
    const params = paging.toParams()
    return await this.getPagingList('/categories', params)
  }

  async createCategory(params: CategoryCreateBody): Promise<Category> {
    return await this.post('/categories', params)
  }

  async updateCategory(
    category: Category,
    params: CategoryUpdateBody
  ): Promise<Category> {
    return await this.patch(`/categories/${category.id}`, params)
  }

  async deleteCategory(category: Category) {
    return await this.delete(`/categories/${category.id}`)
  }

  async exportCategories(encoding: Encoding): Promise<Progress> {
    return await this.get('/categories/csv', { encoding })
  }

  async importCategories(signedId: string): Promise<Progress> {
    return await this.post('/categories/import', { file: signedId })
  }
}
