import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type { ViewSettingParams } from '~/types/body/viewSettingBody'
import type { ViewSettingTicketSearchQuery } from '~/types/body/viewSettingTicketBody'
import type { Encoding } from '~/types/encoding'
import type { Progress } from '~/types/progress'
import type { ViewTicket } from '~/types/ticket'
import type { ViewSetting } from '~/types/viewSetting'

export class KickflowViewSettingApi extends KickflowApi {
  async getViewSettings(
    paging: PagingQuery,
    q: string | null
  ): Promise<PagingList<ViewSetting>> {
    const queryParams = paging.toParams()
    if (q) {
      queryParams.q = q
    }
    return await this.getPagingList('/viewSettings', queryParams)
  }

  async getViewSettingsCreatedByMe(): Promise<Array<ViewSetting>> {
    return await this.get('/viewSettings/createdByMe')
  }

  async getViewSetting(id: string): Promise<ViewSetting> {
    return await this.get(`/viewSettings/${id}`)
  }

  async createViewSetting(params: ViewSettingParams): Promise<ViewSetting> {
    return await this.post('/viewSettings', params)
  }

  async updateViewSetting(
    id: string,
    params: ViewSettingParams
  ): Promise<ViewSetting> {
    return await this.patch(`/viewSettings/${id}`, params)
  }

  async destroyViewSetting(id: string): Promise<ViewSetting> {
    return await this.delete(`/viewSettings/${id}`)
  }

  async getViewSettingTickets(
    viewSettingId: string,
    paging: PagingQuery,
    query?: ViewSettingTicketSearchQuery
  ): Promise<PagingList<ViewTicket>> {
    let queryParams = paging.toParams()
    queryParams = Object.assign(queryParams, query)
    return await this.getPagingList(
      `/viewSettings/${viewSettingId}/tickets`,
      queryParams
    )
  }

  async exportCsv(
    viewSettingId: string,
    encoding: Encoding,
    query?: ViewSettingTicketSearchQuery
  ): Promise<Progress> {
    let queryParams = {
      encoding,
    }

    queryParams = Object.assign(queryParams, query)
    return await this.get(`/viewSettings/${viewSettingId}/export`, queryParams)
  }

  async getViewSettingUsage(): Promise<ViewSetting> {
    return await this.get('/viewSettings/usage')
  }

  async updateViewSettingUsage(viewSettingId: string): Promise<ViewSetting> {
    return await this.put(`/viewSettings/usage`, { viewSettingId })
  }
}
