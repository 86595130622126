import { default as indexCPlPHHYto1Meta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/audit/index.vue?macro=true";
import { default as indexu3J40sRhG2Meta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/authentication/index.vue?macro=true";
import { default as editKKrZaLnQy7Meta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/automations/[id]/edit.vue?macro=true";
import { default as indexLqgGslLfmMMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/automations/index.vue?macro=true";
import { default as newjksFVGIVaWMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/automations/new.vue?macro=true";
import { default as indexN2zS5d9InDMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/billing/index.vue?macro=true";
import { default as indexcMi48DbSMrMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/calculationVariables/index.vue?macro=true";
import { default as indexH8myV8uVEyMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/categories/index.vue?macro=true";
import { default as callback2ClZclD7heMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/box/callback.vue?macro=true";
import { default as indexf40JaUG3ExMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/box/index.vue?macro=true";
import { default as redirectXdBuYrJQRoMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/box/redirect.vue?macro=true";
import { default as indexhowk1bvMsAMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/chatwork/index.vue?macro=true";
import { default as indexksm0aua503Meta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/climberCloud/index.vue?macro=true";
import { default as indexqODH3LZMIyMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/googleDrive/index.vue?macro=true";
import { default as indexaxZAQCV6ntMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/gwsDirectory/index.vue?macro=true";
import { default as results60HQaBZjHvMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/gwsDirectory/results.vue?macro=true";
import { default as indexnx6VM3FbHJMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/lineWorks/index.vue?macro=true";
import { default as callbackVBjVisjvewMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/microsoftTeams/callback.vue?macro=true";
import { default as index6M6zVWsFqmMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/microsoftTeams/index.vue?macro=true";
import { default as indexKd769FuSL2Meta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/serviceAccounts/index.vue?macro=true";
import { default as callbacklMNNozUZACMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/slack/callback.vue?macro=true";
import { default as indexdVvrruBG17Meta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/slack/index.vue?macro=true";
import { default as redirectKTN1Ue4uw9Meta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/slack/redirect.vue?macro=true";
import { default as _91eventId_93WW1a0fJvYpMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/webhook/[id]/events/[eventId].vue?macro=true";
import { default as indexdK3e2JkvX4Meta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/webhook/[id]/index.vue?macro=true";
import { default as indexm62zrqq46kMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/webhook/index.vue?macro=true";
import { default as _91id_93QU2Q4IZqC3Meta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/folders/[id].vue?macro=true";
import { default as indexbuN2BnsJCuMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/folders/index.vue?macro=true";
import { default as indexZ5M3Pf0eNDMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/grades/index.vue?macro=true";
import { default as indextijMsW3y9PMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/index.vue?macro=true";
import { default as indexg7KpuJ6ZsLMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/invitationDomains/index.vue?macro=true";
import { default as index8SfNttQ6MpMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/invitationSetting/index.vue?macro=true";
import { default as index7MqmecrSjBMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/ip/index.vue?macro=true";
import { default as index2Ja9FR5cJXMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/labels/index.vue?macro=true";
import { default as index6Ay5jkAj1RMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/masters/[id]/index.vue?macro=true";
import { default as workflowsh9EaNDbeUvMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/masters/[id]/workflows.vue?macro=true";
import { default as indexHbFSNi7PqXMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/masters/index.vue?macro=true";
import { default as workflowsg5pbNSEU9WMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/numberingGroups/[id]/workflows.vue?macro=true";
import { default as indexf8JqAtiBGNMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/numberingGroups/index.vue?macro=true";
import { default as indexwqMPF2AIPDMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/organizations/[org]/compare/index.vue?macro=true";
import { default as csvcepvokwqT8Meta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/organizations/[org]/csv.vue?macro=true";
import { default as indexyWro04xpOgMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/organizations/[org]/index.vue?macro=true";
import { default as _91id_933ebkxTJUW0Meta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/organizations/[org]/teams/[id].vue?macro=true";
import { default as historytYEayOCZu8Meta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/organizations/history.vue?macro=true";
import { default as indexZCfNWww1jJMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/organizations/index.vue?macro=true";
import { default as _91id_93pSN8gCLL0GMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/pipelines/[id].vue?macro=true";
import { default as indexUY5TL2t2NBMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/pipelines/index.vue?macro=true";
import { default as new3NbHkISMn2Meta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/pipelines/new.vue?macro=true";
import { default as indexeFiefMkc9uMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/proxyApplicants/index.vue?macro=true";
import { default as indexde2sAG289LMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/proxyApprovers/index.vue?macro=true";
import { default as _91id_93b02M66m3srMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/roles/[id].vue?macro=true";
import { default as indexlzA6uRAUkuMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/roles/index.vue?macro=true";
import { default as editeML2jtJtGaMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/routes/[id]/edit.vue?macro=true";
import { default as indexOaoAzO1uPqMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/routes/[id]/index.vue?macro=true";
import { default as index2VIoyMaByWMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/routes/[id]/simulator/index.vue?macro=true";
import { default as _91versionNumber_93C8asstvpKBMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/routes/[id]/versions/[versionNumber].vue?macro=true";
import { default as indexWfA1W9TvibMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/routes/[id]/versions/index.vue?macro=true";
import { default as indexOSE4fvnQDLMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/routes/index.vue?macro=true";
import { default as newGdd4t4jcOEMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/routes/new.vue?macro=true";
import { default as indexZ9q0aPxLSUMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/stats/index.vue?macro=true";
import { default as indexZsMNploLlpMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/subStatuses/index.vue?macro=true";
import { default as assistantsMrHyUKsJm1Meta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/assistants.vue?macro=true";
import { default as backupsXLVwd2WTEnMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/backups.vue?macro=true";
import { default as index4T7kTL0k0zMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/index.vue?macro=true";
import { default as newsbHP1AK9DwXMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/news.vue?macro=true";
import { default as notificationsYutuq6OjDrMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/notifications.vue?macro=true";
import { default as remindqi8ESCIDQTMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/remind.vue?macro=true";
import { default as settings43xA1umXwTMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/settings.vue?macro=true";
import { default as subdomainfzmzRVufOuMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/subdomain.vue?macro=true";
import { default as workinglhb9LqONWYMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/working.vue?macro=true";
import { default as cleanpDjjWl40vYMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/tickets/clean.vue?macro=true";
import { default as myticketvZNYHpSEu8Meta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/tickets/transfer/myticket.vue?macro=true";
import { default as taskDnSqKfZh4UMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/tickets/transfer/task.vue?macro=true";
import { default as deleteGgUsuepxqxMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/tickets/viewer/delete.vue?macro=true";
import { default as indexZWrgV63szgMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/tickets/viewer/index.vue?macro=true";
import { default as _91id_93BrvgLESTLbMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/users/[id].vue?macro=true";
import { default as csvgLDRpUEzNCMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/users/csv.vue?macro=true";
import { default as indexwuKF5fUWKdMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/users/index.vue?macro=true";
import { default as inviteNTow4Tz7UHMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/users/invite.vue?macro=true";
import { default as unaffiliated3SXWgeEXX0Meta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/users/unaffiliated.vue?macro=true";
import { default as editxwkCqk005jMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/workflows/[id]/edit.vue?macro=true";
import { default as index3sFIslYVjeMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/workflows/[id]/index.vue?macro=true";
import { default as _91versionNumber_93odHH663XBUMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/workflows/[id]/versions/[versionNumber].vue?macro=true";
import { default as indexnf4pB66AtfMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/workflows/[id]/versions/index.vue?macro=true";
import { default as indexwnkhg434RsMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/workflows/index.vue?macro=true";
import { default as newIOhMwXPScQMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin/workflows/new.vue?macro=true";
import { default as adminN5dQYxxGrbMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/admin.vue?macro=true";
import { default as signinmnZmKComnkMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/assistants/[id]/signin.vue?macro=true";
import { default as callbackSidTe29pxVMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/auth/callback.vue?macro=true";
import { default as callback2c1jUrK7tKuMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/auth/callback2.vue?macro=true";
import { default as googleCallback2L6xfbhoXuYMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/auth/googleCallback2.vue?macro=true";
import { default as verify_emailYM6SaUDQo9Meta } from "/tmp/build_89936778/frontend_nuxt3/pages/auth/verify_email.vue?macro=true";
import { default as indexB1hw5s6vSYMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/dashboard/index.vue?macro=true";
import { default as indexTOj3DztPkCMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/dashboard/mytickets/index.vue?macro=true";
import { default as newbOHYosXQBHMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/dashboard/mytickets/new.vue?macro=true";
import { default as indexscK8AGnm6AMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/dashboard/notifications/index.vue?macro=true";
import { default as indexgROTdnj4XhMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/dashboard/profile/index.vue?macro=true";
import { default as indexksSG8pL7wCMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/dashboard/security/index.vue?macro=true";
import { default as cloudsign4M9T7piKIsMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/dashboard/settings/cloudsign.vue?macro=true";
import { default as googleChatiGWtkjNjJEMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/dashboard/settings/googleChat.vue?macro=true";
import { default as notificationsXIvCs8u17oMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/dashboard/settings/notifications.vue?macro=true";
import { default as tokens9E8WTk4FdGMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/dashboard/settings/tokens.vue?macro=true";
import { default as indexLcI66sOoxVMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/dashboard/tasks/index.vue?macro=true";
import { default as editXLvVTLfhXeMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/dashboard/tickets/[id]/edit.vue?macro=true";
import { default as editByApproverxs4UCbpfZXMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/dashboard/tickets/[id]/editByApprover.vue?macro=true";
import { default as indexTpTXgcIiuYMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/dashboard/tickets/[id]/index.vue?macro=true";
import { default as printjnR9WUiwobMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/dashboard/tickets/[id]/print.vue?macro=true";
import { default as indexgChCQfoD0WMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/dashboard/tickets/index.vue?macro=true";
import { default as indexcpj06loWTQMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/dashboard/view/index.vue?macro=true";
import { default as editrFOaP2IIptMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/dashboard/view/settings/[id]/edit.vue?macro=true";
import { default as indexR4jnYTavj8Meta } from "/tmp/build_89936778/frontend_nuxt3/pages/dashboard/view/settings/index.vue?macro=true";
import { default as new2Obo8JoQo5Meta } from "/tmp/build_89936778/frontend_nuxt3/pages/dashboard/view/settings/new.vue?macro=true";
import { default as searchTEPnhz8notMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/dashboard/workflows/search.vue?macro=true";
import { default as dashboardVKcfMsqqX6Meta } from "/tmp/build_89936778/frontend_nuxt3/pages/dashboard.vue?macro=true";
import { default as expiredOGGpmbJgkXMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/expired.vue?macro=true";
import { default as indexiLourUiyqWMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/files/[id]/index.vue?macro=true";
import { default as indexXO1ePC315fMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/index.vue?macro=true";
import { default as _91id_93SpmefeVosNMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/invitations/[id].vue?macro=true";
import { default as misocarA3nh1U2I7Meta } from "/tmp/build_89936778/frontend_nuxt3/pages/misoca.vue?macro=true";
import { default as redirectryop8dxYCrMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/redirect.vue?macro=true";
import { default as selectY20rqytz9GMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/select.vue?macro=true";
import { default as signinFbk2vLBL4xMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/signin.vue?macro=true";
import { default as startSsoxU6ANvOrk3Meta } from "/tmp/build_89936778/frontend_nuxt3/pages/startSso.vue?macro=true";
import { default as welcomexXYcz6WIGvMeta } from "/tmp/build_89936778/frontend_nuxt3/pages/welcome.vue?macro=true";
export default [
  {
    name: adminN5dQYxxGrbMeta?.name,
    path: "/en/admin",
    meta: adminN5dQYxxGrbMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin.vue"),
    children: [
  {
    name: "admin-audit___en",
    path: "audit",
    meta: indexCPlPHHYto1Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/audit/index.vue")
  },
  {
    name: "admin-authentication___en",
    path: "authentication",
    meta: indexu3J40sRhG2Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/authentication/index.vue")
  },
  {
    name: "admin-automations-id-edit___en",
    path: "automations/:id()/edit",
    meta: editKKrZaLnQy7Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/automations/[id]/edit.vue")
  },
  {
    name: "admin-automations___en",
    path: "automations",
    meta: indexLqgGslLfmMMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/automations/index.vue")
  },
  {
    name: "admin-automations-new___en",
    path: "automations/new",
    meta: newjksFVGIVaWMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/automations/new.vue")
  },
  {
    name: "admin-billing___en",
    path: "billing",
    meta: indexN2zS5d9InDMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/billing/index.vue")
  },
  {
    name: "admin-calculationVariables___en",
    path: "calculationVariables",
    meta: indexcMi48DbSMrMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/calculationVariables/index.vue")
  },
  {
    name: "admin-categories___en",
    path: "categories",
    meta: indexH8myV8uVEyMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/categories/index.vue")
  },
  {
    name: "admin-connections-box-callback___en",
    path: "connections/box/callback",
    meta: callback2ClZclD7heMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/box/callback.vue")
  },
  {
    name: "admin-connections-box___en",
    path: "connections/box",
    meta: indexf40JaUG3ExMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/box/index.vue")
  },
  {
    name: "admin-connections-box-redirect___en",
    path: "connections/box/redirect",
    meta: redirectXdBuYrJQRoMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/box/redirect.vue")
  },
  {
    name: "admin-connections-chatwork___en",
    path: "connections/chatwork",
    meta: indexhowk1bvMsAMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/chatwork/index.vue")
  },
  {
    name: "admin-connections-climberCloud___en",
    path: "connections/climberCloud",
    meta: indexksm0aua503Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/climberCloud/index.vue")
  },
  {
    name: "admin-connections-googleDrive___en",
    path: "connections/googleDrive",
    meta: indexqODH3LZMIyMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/googleDrive/index.vue")
  },
  {
    name: "admin-connections-gwsDirectory___en",
    path: "connections/gwsDirectory",
    meta: indexaxZAQCV6ntMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/gwsDirectory/index.vue")
  },
  {
    name: "admin-connections-gwsDirectory-results___en",
    path: "connections/gwsDirectory/results",
    meta: results60HQaBZjHvMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/gwsDirectory/results.vue")
  },
  {
    name: "admin-connections-lineWorks___en",
    path: "connections/lineWorks",
    meta: indexnx6VM3FbHJMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/lineWorks/index.vue")
  },
  {
    name: "admin-connections-microsoftTeams-callback___en",
    path: "connections/microsoftTeams/callback",
    meta: callbackVBjVisjvewMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/microsoftTeams/callback.vue")
  },
  {
    name: "admin-connections-microsoftTeams___en",
    path: "connections/microsoftTeams",
    meta: index6M6zVWsFqmMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/microsoftTeams/index.vue")
  },
  {
    name: "admin-connections-serviceAccounts___en",
    path: "connections/serviceAccounts",
    meta: indexKd769FuSL2Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/serviceAccounts/index.vue")
  },
  {
    name: "admin-connections-slack-callback___en",
    path: "connections/slack/callback",
    meta: callbacklMNNozUZACMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/slack/callback.vue")
  },
  {
    name: "admin-connections-slack___en",
    path: "connections/slack",
    meta: indexdVvrruBG17Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/slack/index.vue")
  },
  {
    name: "admin-connections-slack-redirect___en",
    path: "connections/slack/redirect",
    meta: redirectKTN1Ue4uw9Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/slack/redirect.vue")
  },
  {
    name: "admin-connections-webhook-id-events-eventId___en",
    path: "connections/webhook/:id()/events/:eventId()",
    meta: _91eventId_93WW1a0fJvYpMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/webhook/[id]/events/[eventId].vue")
  },
  {
    name: "admin-connections-webhook-id___en",
    path: "connections/webhook/:id()",
    meta: indexdK3e2JkvX4Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/webhook/[id]/index.vue")
  },
  {
    name: "admin-connections-webhook___en",
    path: "connections/webhook",
    meta: indexm62zrqq46kMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/webhook/index.vue")
  },
  {
    name: "admin-folders-id___en",
    path: "folders/:id()",
    meta: _91id_93QU2Q4IZqC3Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/folders/[id].vue")
  },
  {
    name: "admin-folders___en",
    path: "folders",
    meta: indexbuN2BnsJCuMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/folders/index.vue")
  },
  {
    name: "admin-grades___en",
    path: "grades",
    meta: indexZ5M3Pf0eNDMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/grades/index.vue")
  },
  {
    name: "admin___en",
    path: "",
    meta: indextijMsW3y9PMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/index.vue")
  },
  {
    name: "admin-invitationDomains___en",
    path: "invitationDomains",
    meta: indexg7KpuJ6ZsLMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/invitationDomains/index.vue")
  },
  {
    name: "admin-invitationSetting___en",
    path: "invitationSetting",
    meta: index8SfNttQ6MpMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/invitationSetting/index.vue")
  },
  {
    name: "admin-ip___en",
    path: "ip",
    meta: index7MqmecrSjBMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/ip/index.vue")
  },
  {
    name: "admin-labels___en",
    path: "labels",
    meta: index2Ja9FR5cJXMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/labels/index.vue")
  },
  {
    name: "admin-masters-id___en",
    path: "masters/:id()",
    meta: index6Ay5jkAj1RMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/masters/[id]/index.vue")
  },
  {
    name: "admin-masters-id-workflows___en",
    path: "masters/:id()/workflows",
    meta: workflowsh9EaNDbeUvMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/masters/[id]/workflows.vue")
  },
  {
    name: "admin-masters___en",
    path: "masters",
    meta: indexHbFSNi7PqXMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/masters/index.vue")
  },
  {
    name: "admin-numberingGroups-id-workflows___en",
    path: "numberingGroups/:id()/workflows",
    meta: workflowsg5pbNSEU9WMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/numberingGroups/[id]/workflows.vue")
  },
  {
    name: "admin-numberingGroups___en",
    path: "numberingGroups",
    meta: indexf8JqAtiBGNMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/numberingGroups/index.vue")
  },
  {
    name: "admin-organizations-org-compare___en",
    path: "organizations/:org()/compare",
    meta: indexwqMPF2AIPDMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/organizations/[org]/compare/index.vue")
  },
  {
    name: "admin-organizations-org-csv___en",
    path: "organizations/:org()/csv",
    meta: csvcepvokwqT8Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/organizations/[org]/csv.vue")
  },
  {
    name: "admin-organizations-org___en",
    path: "organizations/:org()",
    meta: indexyWro04xpOgMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/organizations/[org]/index.vue")
  },
  {
    name: "admin-organizations-org-teams-id___en",
    path: "organizations/:org()/teams/:id()",
    meta: _91id_933ebkxTJUW0Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/organizations/[org]/teams/[id].vue")
  },
  {
    name: "admin-organizations-history___en",
    path: "organizations/history",
    meta: historytYEayOCZu8Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/organizations/history.vue")
  },
  {
    name: "admin-organizations___en",
    path: "organizations",
    meta: indexZCfNWww1jJMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/organizations/index.vue")
  },
  {
    name: "admin-pipelines-id___en",
    path: "pipelines/:id()",
    meta: _91id_93pSN8gCLL0GMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/pipelines/[id].vue")
  },
  {
    name: "admin-pipelines___en",
    path: "pipelines",
    meta: indexUY5TL2t2NBMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/pipelines/index.vue")
  },
  {
    name: "admin-pipelines-new___en",
    path: "pipelines/new",
    meta: new3NbHkISMn2Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/pipelines/new.vue")
  },
  {
    name: "admin-proxyApplicants___en",
    path: "proxyApplicants",
    meta: indexeFiefMkc9uMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/proxyApplicants/index.vue")
  },
  {
    name: "admin-proxyApprovers___en",
    path: "proxyApprovers",
    meta: indexde2sAG289LMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/proxyApprovers/index.vue")
  },
  {
    name: "admin-roles-id___en",
    path: "roles/:id()",
    meta: _91id_93b02M66m3srMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/roles/[id].vue")
  },
  {
    name: "admin-roles___en",
    path: "roles",
    meta: indexlzA6uRAUkuMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/roles/index.vue")
  },
  {
    name: "admin-routes-id-edit___en",
    path: "routes/:id()/edit",
    meta: editeML2jtJtGaMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/routes/[id]/edit.vue")
  },
  {
    name: "admin-routes-id___en",
    path: "routes/:id()",
    meta: indexOaoAzO1uPqMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/routes/[id]/index.vue")
  },
  {
    name: "admin-routes-id-simulator___en",
    path: "routes/:id()/simulator",
    meta: index2VIoyMaByWMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/routes/[id]/simulator/index.vue")
  },
  {
    name: "admin-routes-id-versions-versionNumber___en",
    path: "routes/:id()/versions/:versionNumber()",
    meta: _91versionNumber_93C8asstvpKBMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/routes/[id]/versions/[versionNumber].vue")
  },
  {
    name: "admin-routes-id-versions___en",
    path: "routes/:id()/versions",
    meta: indexWfA1W9TvibMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/routes/[id]/versions/index.vue")
  },
  {
    name: "admin-routes___en",
    path: "routes",
    meta: indexOSE4fvnQDLMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/routes/index.vue")
  },
  {
    name: "admin-routes-new___en",
    path: "routes/new",
    meta: newGdd4t4jcOEMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/routes/new.vue")
  },
  {
    name: "admin-stats___en",
    path: "stats",
    meta: indexZ9q0aPxLSUMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/stats/index.vue")
  },
  {
    name: "admin-subStatuses___en",
    path: "subStatuses",
    meta: indexZsMNploLlpMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/subStatuses/index.vue")
  },
  {
    name: "admin-tenant-assistants___en",
    path: "tenant/assistants",
    meta: assistantsMrHyUKsJm1Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/assistants.vue")
  },
  {
    name: "admin-tenant-backups___en",
    path: "tenant/backups",
    meta: backupsXLVwd2WTEnMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/backups.vue")
  },
  {
    name: "admin-tenant___en",
    path: "tenant",
    meta: index4T7kTL0k0zMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/index.vue")
  },
  {
    name: "admin-tenant-news___en",
    path: "tenant/news",
    meta: newsbHP1AK9DwXMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/news.vue")
  },
  {
    name: "admin-tenant-notifications___en",
    path: "tenant/notifications",
    meta: notificationsYutuq6OjDrMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/notifications.vue")
  },
  {
    name: "admin-tenant-remind___en",
    path: "tenant/remind",
    meta: remindqi8ESCIDQTMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/remind.vue")
  },
  {
    name: "admin-tenant-settings___en",
    path: "tenant/settings",
    meta: settings43xA1umXwTMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/settings.vue")
  },
  {
    name: "admin-tenant-subdomain___en",
    path: "tenant/subdomain",
    meta: subdomainfzmzRVufOuMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/subdomain.vue")
  },
  {
    name: "admin-tenant-working___en",
    path: "tenant/working",
    meta: workinglhb9LqONWYMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/working.vue")
  },
  {
    name: "admin-tickets-clean___en",
    path: "tickets/clean",
    meta: cleanpDjjWl40vYMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tickets/clean.vue")
  },
  {
    name: "admin-tickets-transfer-myticket___en",
    path: "tickets/transfer/myticket",
    meta: myticketvZNYHpSEu8Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tickets/transfer/myticket.vue")
  },
  {
    name: "admin-tickets-transfer-task___en",
    path: "tickets/transfer/task",
    meta: taskDnSqKfZh4UMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tickets/transfer/task.vue")
  },
  {
    name: "admin-tickets-viewer-delete___en",
    path: "tickets/viewer/delete",
    meta: deleteGgUsuepxqxMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tickets/viewer/delete.vue")
  },
  {
    name: "admin-tickets-viewer___en",
    path: "tickets/viewer",
    meta: indexZWrgV63szgMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tickets/viewer/index.vue")
  },
  {
    name: "admin-users-id___en",
    path: "users/:id()",
    meta: _91id_93BrvgLESTLbMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/users/[id].vue")
  },
  {
    name: "admin-users-csv___en",
    path: "users/csv",
    meta: csvgLDRpUEzNCMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/users/csv.vue")
  },
  {
    name: "admin-users___en",
    path: "users",
    meta: indexwuKF5fUWKdMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/users/index.vue")
  },
  {
    name: "admin-users-invite___en",
    path: "users/invite",
    meta: inviteNTow4Tz7UHMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/users/invite.vue")
  },
  {
    name: "admin-users-unaffiliated___en",
    path: "users/unaffiliated",
    meta: unaffiliated3SXWgeEXX0Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/users/unaffiliated.vue")
  },
  {
    name: "admin-workflows-id-edit___en",
    path: "workflows/:id()/edit",
    meta: editxwkCqk005jMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/workflows/[id]/edit.vue")
  },
  {
    name: "admin-workflows-id___en",
    path: "workflows/:id()",
    meta: index3sFIslYVjeMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/workflows/[id]/index.vue")
  },
  {
    name: "admin-workflows-id-versions-versionNumber___en",
    path: "workflows/:id()/versions/:versionNumber()",
    meta: _91versionNumber_93odHH663XBUMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/workflows/[id]/versions/[versionNumber].vue")
  },
  {
    name: "admin-workflows-id-versions___en",
    path: "workflows/:id()/versions",
    meta: indexnf4pB66AtfMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/workflows/[id]/versions/index.vue")
  },
  {
    name: "admin-workflows___en",
    path: "workflows",
    meta: indexwnkhg434RsMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/workflows/index.vue")
  },
  {
    name: "admin-workflows-new___en",
    path: "workflows/new",
    meta: newIOhMwXPScQMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/workflows/new.vue")
  }
]
  },
  {
    name: adminN5dQYxxGrbMeta?.name,
    path: "/admin",
    meta: adminN5dQYxxGrbMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin.vue"),
    children: [
  {
    name: "admin-audit___ja",
    path: "audit",
    meta: indexCPlPHHYto1Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/audit/index.vue")
  },
  {
    name: "admin-authentication___ja",
    path: "authentication",
    meta: indexu3J40sRhG2Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/authentication/index.vue")
  },
  {
    name: "admin-automations-id-edit___ja",
    path: "automations/:id()/edit",
    meta: editKKrZaLnQy7Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/automations/[id]/edit.vue")
  },
  {
    name: "admin-automations___ja",
    path: "automations",
    meta: indexLqgGslLfmMMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/automations/index.vue")
  },
  {
    name: "admin-automations-new___ja",
    path: "automations/new",
    meta: newjksFVGIVaWMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/automations/new.vue")
  },
  {
    name: "admin-billing___ja",
    path: "billing",
    meta: indexN2zS5d9InDMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/billing/index.vue")
  },
  {
    name: "admin-calculationVariables___ja",
    path: "calculationVariables",
    meta: indexcMi48DbSMrMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/calculationVariables/index.vue")
  },
  {
    name: "admin-categories___ja",
    path: "categories",
    meta: indexH8myV8uVEyMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/categories/index.vue")
  },
  {
    name: "admin-connections-box-callback___ja",
    path: "connections/box/callback",
    meta: callback2ClZclD7heMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/box/callback.vue")
  },
  {
    name: "admin-connections-box___ja",
    path: "connections/box",
    meta: indexf40JaUG3ExMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/box/index.vue")
  },
  {
    name: "admin-connections-box-redirect___ja",
    path: "connections/box/redirect",
    meta: redirectXdBuYrJQRoMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/box/redirect.vue")
  },
  {
    name: "admin-connections-chatwork___ja",
    path: "connections/chatwork",
    meta: indexhowk1bvMsAMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/chatwork/index.vue")
  },
  {
    name: "admin-connections-climberCloud___ja",
    path: "connections/climberCloud",
    meta: indexksm0aua503Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/climberCloud/index.vue")
  },
  {
    name: "admin-connections-googleDrive___ja",
    path: "connections/googleDrive",
    meta: indexqODH3LZMIyMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/googleDrive/index.vue")
  },
  {
    name: "admin-connections-gwsDirectory___ja",
    path: "connections/gwsDirectory",
    meta: indexaxZAQCV6ntMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/gwsDirectory/index.vue")
  },
  {
    name: "admin-connections-gwsDirectory-results___ja",
    path: "connections/gwsDirectory/results",
    meta: results60HQaBZjHvMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/gwsDirectory/results.vue")
  },
  {
    name: "admin-connections-lineWorks___ja",
    path: "connections/lineWorks",
    meta: indexnx6VM3FbHJMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/lineWorks/index.vue")
  },
  {
    name: "admin-connections-microsoftTeams-callback___ja",
    path: "connections/microsoftTeams/callback",
    meta: callbackVBjVisjvewMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/microsoftTeams/callback.vue")
  },
  {
    name: "admin-connections-microsoftTeams___ja",
    path: "connections/microsoftTeams",
    meta: index6M6zVWsFqmMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/microsoftTeams/index.vue")
  },
  {
    name: "admin-connections-serviceAccounts___ja",
    path: "connections/serviceAccounts",
    meta: indexKd769FuSL2Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/serviceAccounts/index.vue")
  },
  {
    name: "admin-connections-slack-callback___ja",
    path: "connections/slack/callback",
    meta: callbacklMNNozUZACMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/slack/callback.vue")
  },
  {
    name: "admin-connections-slack___ja",
    path: "connections/slack",
    meta: indexdVvrruBG17Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/slack/index.vue")
  },
  {
    name: "admin-connections-slack-redirect___ja",
    path: "connections/slack/redirect",
    meta: redirectKTN1Ue4uw9Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/slack/redirect.vue")
  },
  {
    name: "admin-connections-webhook-id-events-eventId___ja",
    path: "connections/webhook/:id()/events/:eventId()",
    meta: _91eventId_93WW1a0fJvYpMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/webhook/[id]/events/[eventId].vue")
  },
  {
    name: "admin-connections-webhook-id___ja",
    path: "connections/webhook/:id()",
    meta: indexdK3e2JkvX4Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/webhook/[id]/index.vue")
  },
  {
    name: "admin-connections-webhook___ja",
    path: "connections/webhook",
    meta: indexm62zrqq46kMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/connections/webhook/index.vue")
  },
  {
    name: "admin-folders-id___ja",
    path: "folders/:id()",
    meta: _91id_93QU2Q4IZqC3Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/folders/[id].vue")
  },
  {
    name: "admin-folders___ja",
    path: "folders",
    meta: indexbuN2BnsJCuMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/folders/index.vue")
  },
  {
    name: "admin-grades___ja",
    path: "grades",
    meta: indexZ5M3Pf0eNDMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/grades/index.vue")
  },
  {
    name: "admin___ja",
    path: "",
    meta: indextijMsW3y9PMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/index.vue")
  },
  {
    name: "admin-invitationDomains___ja",
    path: "invitationDomains",
    meta: indexg7KpuJ6ZsLMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/invitationDomains/index.vue")
  },
  {
    name: "admin-invitationSetting___ja",
    path: "invitationSetting",
    meta: index8SfNttQ6MpMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/invitationSetting/index.vue")
  },
  {
    name: "admin-ip___ja",
    path: "ip",
    meta: index7MqmecrSjBMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/ip/index.vue")
  },
  {
    name: "admin-labels___ja",
    path: "labels",
    meta: index2Ja9FR5cJXMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/labels/index.vue")
  },
  {
    name: "admin-masters-id___ja",
    path: "masters/:id()",
    meta: index6Ay5jkAj1RMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/masters/[id]/index.vue")
  },
  {
    name: "admin-masters-id-workflows___ja",
    path: "masters/:id()/workflows",
    meta: workflowsh9EaNDbeUvMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/masters/[id]/workflows.vue")
  },
  {
    name: "admin-masters___ja",
    path: "masters",
    meta: indexHbFSNi7PqXMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/masters/index.vue")
  },
  {
    name: "admin-numberingGroups-id-workflows___ja",
    path: "numberingGroups/:id()/workflows",
    meta: workflowsg5pbNSEU9WMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/numberingGroups/[id]/workflows.vue")
  },
  {
    name: "admin-numberingGroups___ja",
    path: "numberingGroups",
    meta: indexf8JqAtiBGNMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/numberingGroups/index.vue")
  },
  {
    name: "admin-organizations-org-compare___ja",
    path: "organizations/:org()/compare",
    meta: indexwqMPF2AIPDMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/organizations/[org]/compare/index.vue")
  },
  {
    name: "admin-organizations-org-csv___ja",
    path: "organizations/:org()/csv",
    meta: csvcepvokwqT8Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/organizations/[org]/csv.vue")
  },
  {
    name: "admin-organizations-org___ja",
    path: "organizations/:org()",
    meta: indexyWro04xpOgMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/organizations/[org]/index.vue")
  },
  {
    name: "admin-organizations-org-teams-id___ja",
    path: "organizations/:org()/teams/:id()",
    meta: _91id_933ebkxTJUW0Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/organizations/[org]/teams/[id].vue")
  },
  {
    name: "admin-organizations-history___ja",
    path: "organizations/history",
    meta: historytYEayOCZu8Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/organizations/history.vue")
  },
  {
    name: "admin-organizations___ja",
    path: "organizations",
    meta: indexZCfNWww1jJMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/organizations/index.vue")
  },
  {
    name: "admin-pipelines-id___ja",
    path: "pipelines/:id()",
    meta: _91id_93pSN8gCLL0GMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/pipelines/[id].vue")
  },
  {
    name: "admin-pipelines___ja",
    path: "pipelines",
    meta: indexUY5TL2t2NBMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/pipelines/index.vue")
  },
  {
    name: "admin-pipelines-new___ja",
    path: "pipelines/new",
    meta: new3NbHkISMn2Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/pipelines/new.vue")
  },
  {
    name: "admin-proxyApplicants___ja",
    path: "proxyApplicants",
    meta: indexeFiefMkc9uMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/proxyApplicants/index.vue")
  },
  {
    name: "admin-proxyApprovers___ja",
    path: "proxyApprovers",
    meta: indexde2sAG289LMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/proxyApprovers/index.vue")
  },
  {
    name: "admin-roles-id___ja",
    path: "roles/:id()",
    meta: _91id_93b02M66m3srMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/roles/[id].vue")
  },
  {
    name: "admin-roles___ja",
    path: "roles",
    meta: indexlzA6uRAUkuMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/roles/index.vue")
  },
  {
    name: "admin-routes-id-edit___ja",
    path: "routes/:id()/edit",
    meta: editeML2jtJtGaMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/routes/[id]/edit.vue")
  },
  {
    name: "admin-routes-id___ja",
    path: "routes/:id()",
    meta: indexOaoAzO1uPqMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/routes/[id]/index.vue")
  },
  {
    name: "admin-routes-id-simulator___ja",
    path: "routes/:id()/simulator",
    meta: index2VIoyMaByWMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/routes/[id]/simulator/index.vue")
  },
  {
    name: "admin-routes-id-versions-versionNumber___ja",
    path: "routes/:id()/versions/:versionNumber()",
    meta: _91versionNumber_93C8asstvpKBMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/routes/[id]/versions/[versionNumber].vue")
  },
  {
    name: "admin-routes-id-versions___ja",
    path: "routes/:id()/versions",
    meta: indexWfA1W9TvibMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/routes/[id]/versions/index.vue")
  },
  {
    name: "admin-routes___ja",
    path: "routes",
    meta: indexOSE4fvnQDLMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/routes/index.vue")
  },
  {
    name: "admin-routes-new___ja",
    path: "routes/new",
    meta: newGdd4t4jcOEMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/routes/new.vue")
  },
  {
    name: "admin-stats___ja",
    path: "stats",
    meta: indexZ9q0aPxLSUMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/stats/index.vue")
  },
  {
    name: "admin-subStatuses___ja",
    path: "subStatuses",
    meta: indexZsMNploLlpMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/subStatuses/index.vue")
  },
  {
    name: "admin-tenant-assistants___ja",
    path: "tenant/assistants",
    meta: assistantsMrHyUKsJm1Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/assistants.vue")
  },
  {
    name: "admin-tenant-backups___ja",
    path: "tenant/backups",
    meta: backupsXLVwd2WTEnMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/backups.vue")
  },
  {
    name: "admin-tenant___ja",
    path: "tenant",
    meta: index4T7kTL0k0zMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/index.vue")
  },
  {
    name: "admin-tenant-news___ja",
    path: "tenant/news",
    meta: newsbHP1AK9DwXMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/news.vue")
  },
  {
    name: "admin-tenant-notifications___ja",
    path: "tenant/notifications",
    meta: notificationsYutuq6OjDrMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/notifications.vue")
  },
  {
    name: "admin-tenant-remind___ja",
    path: "tenant/remind",
    meta: remindqi8ESCIDQTMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/remind.vue")
  },
  {
    name: "admin-tenant-settings___ja",
    path: "tenant/settings",
    meta: settings43xA1umXwTMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/settings.vue")
  },
  {
    name: "admin-tenant-subdomain___ja",
    path: "tenant/subdomain",
    meta: subdomainfzmzRVufOuMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/subdomain.vue")
  },
  {
    name: "admin-tenant-working___ja",
    path: "tenant/working",
    meta: workinglhb9LqONWYMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tenant/working.vue")
  },
  {
    name: "admin-tickets-clean___ja",
    path: "tickets/clean",
    meta: cleanpDjjWl40vYMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tickets/clean.vue")
  },
  {
    name: "admin-tickets-transfer-myticket___ja",
    path: "tickets/transfer/myticket",
    meta: myticketvZNYHpSEu8Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tickets/transfer/myticket.vue")
  },
  {
    name: "admin-tickets-transfer-task___ja",
    path: "tickets/transfer/task",
    meta: taskDnSqKfZh4UMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tickets/transfer/task.vue")
  },
  {
    name: "admin-tickets-viewer-delete___ja",
    path: "tickets/viewer/delete",
    meta: deleteGgUsuepxqxMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tickets/viewer/delete.vue")
  },
  {
    name: "admin-tickets-viewer___ja",
    path: "tickets/viewer",
    meta: indexZWrgV63szgMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/tickets/viewer/index.vue")
  },
  {
    name: "admin-users-id___ja",
    path: "users/:id()",
    meta: _91id_93BrvgLESTLbMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/users/[id].vue")
  },
  {
    name: "admin-users-csv___ja",
    path: "users/csv",
    meta: csvgLDRpUEzNCMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/users/csv.vue")
  },
  {
    name: "admin-users___ja",
    path: "users",
    meta: indexwuKF5fUWKdMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/users/index.vue")
  },
  {
    name: "admin-users-invite___ja",
    path: "users/invite",
    meta: inviteNTow4Tz7UHMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/users/invite.vue")
  },
  {
    name: "admin-users-unaffiliated___ja",
    path: "users/unaffiliated",
    meta: unaffiliated3SXWgeEXX0Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/users/unaffiliated.vue")
  },
  {
    name: "admin-workflows-id-edit___ja",
    path: "workflows/:id()/edit",
    meta: editxwkCqk005jMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/workflows/[id]/edit.vue")
  },
  {
    name: "admin-workflows-id___ja",
    path: "workflows/:id()",
    meta: index3sFIslYVjeMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/workflows/[id]/index.vue")
  },
  {
    name: "admin-workflows-id-versions-versionNumber___ja",
    path: "workflows/:id()/versions/:versionNumber()",
    meta: _91versionNumber_93odHH663XBUMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/workflows/[id]/versions/[versionNumber].vue")
  },
  {
    name: "admin-workflows-id-versions___ja",
    path: "workflows/:id()/versions",
    meta: indexnf4pB66AtfMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/workflows/[id]/versions/index.vue")
  },
  {
    name: "admin-workflows___ja",
    path: "workflows",
    meta: indexwnkhg434RsMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/workflows/index.vue")
  },
  {
    name: "admin-workflows-new___ja",
    path: "workflows/new",
    meta: newIOhMwXPScQMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/admin/workflows/new.vue")
  }
]
  },
  {
    name: "assistants-id-signin___en",
    path: "/en/assistants/:id()/signin",
    meta: signinmnZmKComnkMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/assistants/[id]/signin.vue")
  },
  {
    name: "assistants-id-signin___ja",
    path: "/assistants/:id()/signin",
    meta: signinmnZmKComnkMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/assistants/[id]/signin.vue")
  },
  {
    name: "auth-callback___en",
    path: "/en/auth/callback",
    meta: callbackSidTe29pxVMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/auth/callback.vue")
  },
  {
    name: "auth-callback___ja",
    path: "/auth/callback",
    meta: callbackSidTe29pxVMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/auth/callback.vue")
  },
  {
    name: "auth-callback2___en",
    path: "/en/auth/callback2",
    meta: callback2c1jUrK7tKuMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/auth/callback2.vue")
  },
  {
    name: "auth-callback2___ja",
    path: "/auth/callback2",
    meta: callback2c1jUrK7tKuMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/auth/callback2.vue")
  },
  {
    name: "auth-googleCallback2___en",
    path: "/en/auth/googleCallback2",
    meta: googleCallback2L6xfbhoXuYMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/auth/googleCallback2.vue")
  },
  {
    name: "auth-googleCallback2___ja",
    path: "/auth/googleCallback2",
    meta: googleCallback2L6xfbhoXuYMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/auth/googleCallback2.vue")
  },
  {
    name: "auth-verify_email___en",
    path: "/en/auth/verify_email",
    meta: verify_emailYM6SaUDQo9Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/auth/verify_email.vue")
  },
  {
    name: "auth-verify_email___ja",
    path: "/auth/verify_email",
    meta: verify_emailYM6SaUDQo9Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/auth/verify_email.vue")
  },
  {
    name: dashboardVKcfMsqqX6Meta?.name,
    path: "/en/dashboard",
    meta: dashboardVKcfMsqqX6Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard___en",
    path: "",
    meta: indexB1hw5s6vSYMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-mytickets___en",
    path: "mytickets",
    meta: indexTOj3DztPkCMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/mytickets/index.vue")
  },
  {
    name: "dashboard-mytickets-new___en",
    path: "mytickets/new",
    meta: newbOHYosXQBHMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/mytickets/new.vue")
  },
  {
    name: "dashboard-notifications___en",
    path: "notifications",
    meta: indexscK8AGnm6AMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/notifications/index.vue")
  },
  {
    name: "dashboard-profile___en",
    path: "profile",
    meta: indexgROTdnj4XhMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/profile/index.vue")
  },
  {
    name: "dashboard-security___en",
    path: "security",
    meta: indexksSG8pL7wCMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/security/index.vue")
  },
  {
    name: "dashboard-settings-cloudsign___en",
    path: "settings/cloudsign",
    meta: cloudsign4M9T7piKIsMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/settings/cloudsign.vue")
  },
  {
    name: "dashboard-settings-googleChat___en",
    path: "settings/googleChat",
    meta: googleChatiGWtkjNjJEMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/settings/googleChat.vue")
  },
  {
    name: "dashboard-settings-notifications___en",
    path: "settings/notifications",
    meta: notificationsXIvCs8u17oMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/settings/notifications.vue")
  },
  {
    name: "dashboard-settings-tokens___en",
    path: "settings/tokens",
    meta: tokens9E8WTk4FdGMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/settings/tokens.vue")
  },
  {
    name: "dashboard-tasks___en",
    path: "tasks",
    meta: indexLcI66sOoxVMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/tasks/index.vue")
  },
  {
    name: "dashboard-tickets-id-edit___en",
    path: "tickets/:id()/edit",
    meta: editXLvVTLfhXeMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/tickets/[id]/edit.vue")
  },
  {
    name: "dashboard-tickets-id-editByApprover___en",
    path: "tickets/:id()/editByApprover",
    meta: editByApproverxs4UCbpfZXMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/tickets/[id]/editByApprover.vue")
  },
  {
    name: "dashboard-tickets-id___en",
    path: "tickets/:id()",
    meta: indexTpTXgcIiuYMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/tickets/[id]/index.vue")
  },
  {
    name: "dashboard-tickets-id-print___en",
    path: "tickets/:id()/print",
    meta: printjnR9WUiwobMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/tickets/[id]/print.vue")
  },
  {
    name: "dashboard-tickets___en",
    path: "tickets",
    meta: indexgChCQfoD0WMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/tickets/index.vue")
  },
  {
    name: "dashboard-view___en",
    path: "view",
    meta: indexcpj06loWTQMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/view/index.vue")
  },
  {
    name: "dashboard-view-settings-id-edit___en",
    path: "view/settings/:id()/edit",
    meta: editrFOaP2IIptMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/view/settings/[id]/edit.vue")
  },
  {
    name: "dashboard-view-settings___en",
    path: "view/settings",
    meta: indexR4jnYTavj8Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/view/settings/index.vue")
  },
  {
    name: "dashboard-view-settings-new___en",
    path: "view/settings/new",
    meta: new2Obo8JoQo5Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/view/settings/new.vue")
  },
  {
    name: "dashboard-workflows-search___en",
    path: "workflows/search",
    meta: searchTEPnhz8notMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/workflows/search.vue")
  }
]
  },
  {
    name: dashboardVKcfMsqqX6Meta?.name,
    path: "/dashboard",
    meta: dashboardVKcfMsqqX6Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard___ja",
    path: "",
    meta: indexB1hw5s6vSYMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-mytickets___ja",
    path: "mytickets",
    meta: indexTOj3DztPkCMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/mytickets/index.vue")
  },
  {
    name: "dashboard-mytickets-new___ja",
    path: "mytickets/new",
    meta: newbOHYosXQBHMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/mytickets/new.vue")
  },
  {
    name: "dashboard-notifications___ja",
    path: "notifications",
    meta: indexscK8AGnm6AMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/notifications/index.vue")
  },
  {
    name: "dashboard-profile___ja",
    path: "profile",
    meta: indexgROTdnj4XhMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/profile/index.vue")
  },
  {
    name: "dashboard-security___ja",
    path: "security",
    meta: indexksSG8pL7wCMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/security/index.vue")
  },
  {
    name: "dashboard-settings-cloudsign___ja",
    path: "settings/cloudsign",
    meta: cloudsign4M9T7piKIsMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/settings/cloudsign.vue")
  },
  {
    name: "dashboard-settings-googleChat___ja",
    path: "settings/googleChat",
    meta: googleChatiGWtkjNjJEMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/settings/googleChat.vue")
  },
  {
    name: "dashboard-settings-notifications___ja",
    path: "settings/notifications",
    meta: notificationsXIvCs8u17oMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/settings/notifications.vue")
  },
  {
    name: "dashboard-settings-tokens___ja",
    path: "settings/tokens",
    meta: tokens9E8WTk4FdGMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/settings/tokens.vue")
  },
  {
    name: "dashboard-tasks___ja",
    path: "tasks",
    meta: indexLcI66sOoxVMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/tasks/index.vue")
  },
  {
    name: "dashboard-tickets-id-edit___ja",
    path: "tickets/:id()/edit",
    meta: editXLvVTLfhXeMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/tickets/[id]/edit.vue")
  },
  {
    name: "dashboard-tickets-id-editByApprover___ja",
    path: "tickets/:id()/editByApprover",
    meta: editByApproverxs4UCbpfZXMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/tickets/[id]/editByApprover.vue")
  },
  {
    name: "dashboard-tickets-id___ja",
    path: "tickets/:id()",
    meta: indexTpTXgcIiuYMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/tickets/[id]/index.vue")
  },
  {
    name: "dashboard-tickets-id-print___ja",
    path: "tickets/:id()/print",
    meta: printjnR9WUiwobMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/tickets/[id]/print.vue")
  },
  {
    name: "dashboard-tickets___ja",
    path: "tickets",
    meta: indexgChCQfoD0WMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/tickets/index.vue")
  },
  {
    name: "dashboard-view___ja",
    path: "view",
    meta: indexcpj06loWTQMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/view/index.vue")
  },
  {
    name: "dashboard-view-settings-id-edit___ja",
    path: "view/settings/:id()/edit",
    meta: editrFOaP2IIptMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/view/settings/[id]/edit.vue")
  },
  {
    name: "dashboard-view-settings___ja",
    path: "view/settings",
    meta: indexR4jnYTavj8Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/view/settings/index.vue")
  },
  {
    name: "dashboard-view-settings-new___ja",
    path: "view/settings/new",
    meta: new2Obo8JoQo5Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/view/settings/new.vue")
  },
  {
    name: "dashboard-workflows-search___ja",
    path: "workflows/search",
    meta: searchTEPnhz8notMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/dashboard/workflows/search.vue")
  }
]
  },
  {
    name: "expired___en",
    path: "/en/expired",
    meta: expiredOGGpmbJgkXMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/expired.vue")
  },
  {
    name: "expired___ja",
    path: "/expired",
    meta: expiredOGGpmbJgkXMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/expired.vue")
  },
  {
    name: "files-id___en",
    path: "/en/files/:id()",
    meta: indexiLourUiyqWMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/files/[id]/index.vue")
  },
  {
    name: "files-id___ja",
    path: "/files/:id()",
    meta: indexiLourUiyqWMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/files/[id]/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexXO1ePC315fMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/index.vue")
  },
  {
    name: "index___ja",
    path: "/",
    meta: indexXO1ePC315fMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/index.vue")
  },
  {
    name: "invitations-id___en",
    path: "/en/invitations/:id()",
    meta: _91id_93SpmefeVosNMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/invitations/[id].vue")
  },
  {
    name: "invitations-id___ja",
    path: "/invitations/:id()",
    meta: _91id_93SpmefeVosNMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/invitations/[id].vue")
  },
  {
    name: "misoca___en",
    path: "/en/misoca",
    meta: misocarA3nh1U2I7Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/misoca.vue")
  },
  {
    name: "misoca___ja",
    path: "/misoca",
    meta: misocarA3nh1U2I7Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/misoca.vue")
  },
  {
    name: "redirect___en",
    path: "/en/redirect",
    meta: redirectryop8dxYCrMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/redirect.vue")
  },
  {
    name: "redirect___ja",
    path: "/redirect",
    meta: redirectryop8dxYCrMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/redirect.vue")
  },
  {
    name: "select___en",
    path: "/en/select",
    meta: selectY20rqytz9GMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/select.vue")
  },
  {
    name: "select___ja",
    path: "/select",
    meta: selectY20rqytz9GMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/select.vue")
  },
  {
    name: "signin___en",
    path: "/en/signin",
    meta: signinFbk2vLBL4xMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/signin.vue")
  },
  {
    name: "signin___ja",
    path: "/signin",
    meta: signinFbk2vLBL4xMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/signin.vue")
  },
  {
    name: "startSso___en",
    path: "/en/startSso",
    meta: startSsoxU6ANvOrk3Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/startSso.vue")
  },
  {
    name: "startSso___ja",
    path: "/startSso",
    meta: startSsoxU6ANvOrk3Meta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/startSso.vue")
  },
  {
    name: "welcome___en",
    path: "/en/welcome",
    meta: welcomexXYcz6WIGvMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/welcome.vue")
  },
  {
    name: "welcome___ja",
    path: "/welcome",
    meta: welcomexXYcz6WIGvMeta || {},
    component: () => import("/tmp/build_89936778/frontend_nuxt3/pages/welcome.vue")
  }
]